<template>
  <div class="home">
    <div class="top-middle">
      <div class="top-header">
        <div class="top-header-left">
          <div class="left-container"></div>
          </div>
        <div class="top-header-right">
          <h4 style="font-weight: 900;">博鳌亚洲论坛2024年年会</h4>
          <h4 style="font-weight: 100; color: #eeeeee;">BFA Annual Conference 2024</h4>
        </div>
      </div>
      <div class="login-container">
        <div class="language">
          <div class="language-button">
            <span style="cursor: pointer;" @click="onSelect({ code: 'zh' })"> 中 </span>
            <span v-html="'&nbsp;/&nbsp;'"></span>
            <span style="cursor: pointer;" @click="onSelect({ code:'en' })"> EN </span>
          </div>
        </div>

        <div class="login">
          <div style="text-align: center">
            <div class="company-name">博鳌亚洲论坛酒店预订系统</div>
            <div class="company-en-name">Boao Forum For Asia Hotel Reservation Service</div>
          </div>
          <div v-if="language === 'zh'" class="login-field">
            <div>{{ $t('lastName') }}<span style="color: red;">（请输入拼音）</span></div>
            <input
              type="text"
              v-model="last"
              maxlength="50"
              @blur="last = last.trim()"
            />
          </div>
          <div v-if="language === 'zh'" class="login-field">
            <div>{{ $t('firstName') }}<span style="color: red;">（请输入拼音）</span></div>
            <input
              type="text"
              v-model="first"
              maxlength="50"
              @blur="first = first.trim()"
            />
          </div>

          <div v-if="language === 'en'" class="login-field">
            <div>{{ $t('firstName') }}</div>
            <input
              type="text"
              v-model="first"
              maxlength="50"
              @blur="first = first.trim()"
            />
          </div>
          <div v-if="language === 'en'" class="login-field">
            <div>{{ $t('lastName') }}</div>
            <input
              type="text"
              v-model="last"
              maxlength="50"
              @blur="last = last.trim()"
            />
          </div>

          <div class="login-field">
            <div>{{ $t('invitationCode') }}</div>
            <input
              type="text"
              v-model="invitationCode"
              maxlength="50"
              @blur="invitationCode = invitationCode.trim()"
            >
          </div>

          <van-button
            class="login-button"
            block
            @click="login"
            :loading="loading"
          >
            {{ $t('login') }}
          </van-button>
        </div>
      </div>
    </div>

    <div class="footer">
      <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start;">
        <div style="font-size: 14px; padding: 0 20px; color: #1245CA;">
          <h3 style="margin: 8px 0; ">{{ $t('for more information') }}</h3>
          <div>
            <span>Tel: +86-400-098-2996</span>
            <span style="font-weight: 100;">Email: hotel@bjome.com.cn</span>
          </div>
        </div>
      </div>

      <van-divider />

      <div class="icons">
        <img :src="officeWeb" width="40" @click="goOffice">
        <img :src="officialAccounts" width="40" @click="preview(1)">
        <img :src="appLogo" width="40" @click="preview(2)">
        <img :src="weibo" width="40" @click="preview(3)">
      </div>
    </div>

    <van-popup v-model="showService" closeable :style="{ 'min-height': '50%', width: '86%' }">
      <div style="padding: 2.5em 1.4em;">
        <van-row type="flex">
          <van-col span="5" style="background: #eee; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div>
              <van-icon name="warning-o" size="40" color="orange" />
            </div>
            <div>
              {{ $t('sorry') }}
            </div>
          </van-col>
          <van-col span="19" style="padding: 0 0 0 10px;">
            <div class="word">{{ $t('login notify') }}</div>
            <div class="word">
              <span>{{ $t('customer tel') }}: </span>
              <span class="important-text">+86-400-098-2996</span>
            </div>
            <div class="word">
              <span>{{ $t('customer email') }}: </span>
              <span class="important-text">hotel@bjome.com.cn</span>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-popup>

    <van-popup
      v-model="show"
      closeable
      position="bottom"
      :style="{ height: '40%', textAlign: 'center' }"
    >
      <img style="margin-top: 40px;" v-show="imageIndex === 1" src="@/assets/wechat_qr.jpg" width="300">
      <img style="margin-top: 40px;" v-show="imageIndex === 2" src="@/assets/wechat_qr.jpg" width="300">
      <img style="margin-top: 40px;" v-show="imageIndex === 3" src="@/assets/wechat_qr.jpg" width="300">
    </van-popup>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { mapState } from 'vuex'
import { vanLocales } from '@/lang'

export default {
  name: 'Home',
  components: {

  },
  data () {
    return {
      officeWeb: require('@/assets/office_web.png'),
      officialAccounts: require('@/assets/official_accounts.png'),
      appLogo: require('@/assets/app_logo.png'),
      weibo: require('@/assets/weibo.png'),
      wechatQR: require('@/assets/wechat_qr.jpg'),
      weiboQR: require('@/assets/weibo_qr.jpg'),
      appQR: require('@/assets/app_qr.jpg'),
      showLanguage: false,
      languages: [
        { code: 'zh', name: '中文' },
        { code: 'en', name: 'English' }
      ],
      last: '',
      first: '',
      invitationCode: '',
      showService: false,
      show: false,
      imageIndex: 0,
      loading: false
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  methods: {
    goOffice () {
      window.open('https://www.boaoforum.org', '_blank')
    },
    preview (index) {
      this.imageIndex = index
      this.show = true
    },
    onSelect (value) {
      this.$i18n.locale = value.code
      vanLocales(value.code)
      this.$store.dispatch('setLanguage', value.code)
    },
    check () {
      if (!this.last || !this.last.trim()) {
        return false
      }

      if (!this.first || !this.first.trim()) {
        return false
      }

      if (!this.invitationCode || !this.invitationCode.trim()) {
        return false
      }

      return true
    },
    async login () {
      // check user input
      if (this.check()) {
        // backend login
        try {
          this.loading = true
          const res = await this.$axios.post('/invitation/login', {
            last: this.last.trim(),
            first: this.first.trim(),
            invitationCode: this.invitationCode.trim()
          })

          this.$store.dispatch('setToken', res.token)
          this.$router.push('search')
        } catch (e) {
          Notify(e.message)
          this.showService = true
          console.error(e)
        } finally {
          this.loading = false
        }
      } else {
        this.showService = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top-middle {
  flex: 1;
  background-image: url('~@/assets/home_background.jpg');
  //background-size: cover;
}

.top-header {
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;

  .top-header-left {
    padding-left: 16px;
    .left-container {
      background-image: url('~@/assets/boao2.png');
      background-size: cover;
      width: 120px;
      height: 46px;
    }
  }

  .top-header-left, .top-header-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .top-header-right {
    flex: 1;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  h4 {
    margin: 0;
    color: white;
  }
}

.login-container {
  margin: 0 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border-radius: 10px;

  .language-button {
    width: 80px;
    height: 34px;
    border-radius: 17px;
    background-color: #2251DB;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .company-name {
    margin-top: 30px;
  }

  .company-name, .company-en-name {
    color: #0024C8;
    font-weight: bold;
  }

  .company-en-name {
    font-weight: unset;
  }

  .login {
    width: 86%;
    max-width: 600px;
    padding-bottom: 50px;
  }
}

.language {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 20px 0 0;
}

.login-field > input {
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  padding: 0 16px;
  border-radius: 6px;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #c8c9cc;
  //background-color: #f5f6f7;
}

.login-field {
  margin-top: 20px;
  div:first-child {
    color: #636363;
    padding-bottom: 8px;
    box-sizing: border-box;
    height: 30px;
  }
}

.login-field:not(:first-child) {
  margin-top: 16px;
}

.login-button {
  margin-top: 30px;
  border-radius: 10px;
  background-color: #1547D2;
  color: white;
  font-size: 1.2em;
  font-weight: bolder;
}

.footer {
  background-color: rgba(255, 255, 255, 0.96);
  color: #9e9e9e;
  font-size: 14px;
  padding-bottom: 20px;
  span {
    padding-right: 15px;
  }

  .icons {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
}

.link {
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif !important;
  color: #9e9e9e;
}
</style>
